/*
 * @TODO IMPORTANT
 * THIS APP'S AUTH IS NOT FULLY readyState
 * When using any of the available Social Identity Providers, you need to register
 * your application with the relevant Identity Provider in order to obtain
 * a Client ID and Client Secret.
 * https://auth0.com/docs/connections/social/devkeys
 */

import createAuth0Client from '@auth0/auth0-spa-js'
import {error} from './notifications'

let auth0 = null
const [client_id, domain, audience] = [
  process.env.AUTH0_CLIENT_ID,
  process.env.AUTH0_DOMAIN,
  process.env.AUTH0_AUDIENCE
]

const initialise_auth = async () =>
  (auth0 = await createAuth0Client({client_id, domain, audience}))

const get_error = (search_params) => {
  const type = search_params.get('error')

  if(!type) {
    return null
  }

  return {
    type,
    message: search_params.get('error_description')
  }
}

const clear_session = () => {
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('user')
}

export const logout = async () => {
  if(!auth0) {
    await initialise_auth()
  }

  clear_session()

  await auth0.logout({returnTo: window.location.origin})
}

export const authenticate = async () => {
  if(!auth0) {
    await initialise_auth()
  }

  const search_params = new URLSearchParams(location.search)

  const auth0_error = get_error(search_params)
  if(auth0_error) {
    throw new Error(auth0_error.message || '', {cause: auth0_error})
  }

  const is_callback = search_params.get('code') && search_params.get('state')

  if(!(await auth0.isAuthenticated()) && !is_callback) {
    clear_session()
    error('Please login to the Admin App')

    return auth0.loginWithRedirect({
      redirect_uri: location.origin,
      appState: {}
    })
  }

  if(is_callback) {
    const {appState} = await auth0.handleRedirectCallback()
    window.history.replaceState({}, document.title, '/')
  }

  if(!sessionStorage.access_token || !sessionStorage.user) {
    sessionStorage.access_token = await auth0.getTokenSilently()
    sessionStorage.user = JSON.stringify(await auth0.getUser())
  }
}
