import api from '../api'
import {success, error} from '../notifications'

function date_greater_than(date_string_1, date_string_2) {
  const date1 = new Date(date_string_1)
  const date2 = new Date(date_string_2)

  return date1 > date2
}

export async function run_odr(event) {
  event.preventDefault()

  const from = document.getElementById('run_odr_from').value
  const to = document.getElementById('run_odr_to').value

  if(date_greater_than(from, to)) {
    return error(
      'The "From" date needs to be the same or earlier than the "To" date.'
    )
  }

  const data = {from, to}
  try {
    const response_data = await api.post('/donations', data)
    const status_code = response_data.StatusCode

    if(status_code !== 202) {
      return error(
        `Something went wrong. Please try again. Status code: ${status_code}.`
      )
    }

    success(
      'The ODR process has been scheduled to run. You will receive an email soon.',
      2000
    )
  } catch (error) {
    console.log(error)
    error('Something went wrong. Please try again.')
  }
}
