import api from '../api'
import {success, error} from '../notifications'
import {get_gmail_url} from '../utils'

const GENERIC_ERROR_MESSAGE = 'We had an error in our system. Please try again.'

export async function member_delete(event) {
  event.preventDefault()

  const email = document.getElementById('member_delete_email').value.toLowerCase()

  try {
    const report = await api.delete(`/members?email=${email}`)
    const systems = []

    for(const key in report) {
      // check if the value is true and then add it to systems
      if(report[key] === true) {
        systems.push(key)
      }
    }

    if(systems.length === 0) {
      return error(`Member ${email} does not exist in any of our systems.`)
    }

    const systems_to_delete = systems.join(', ')

    const gmail_body = `
    ${email}
    %0A%0A
    ${systems_to_delete}
    `
    const asana_task_gmail_url = get_gmail_url(
      `${process.env.ASANA_TECH_EMAIL},${process.env.ASANA_TASK_ASSIGNEE}`,
      `Member%20delete ${email} `,
      gmail_body
    )

    const action_button = `
    <div class="d-flex" id="assign_task_button">
      <a href="${asana_task_gmail_url}" target="_blank" type="button" class="btn btn-secondary ms-auto">Assign to Tech Team</a>
    </div>
    `

    success(
      `Member ${email} needs to be deleted from ${systems_to_delete}.`,
      null,
      action_button
    )
  } catch (e) {
    console.error(e)

    error(GENERIC_ERROR_MESSAGE)
  }
}

export async function member_merge(event) {
  event.preventDefault()

  let old_email = document.getElementById('member_merge_old_email').value.toLowerCase()
  let new_email = document.getElementById('member_merge_new_email').value.toLowerCase()
  const data = {old_email, new_email}
  
  try {
    const {instructions} = await api.post('/members/merge', data)
    let instructions_html = ''
    let instructions_text = ''

    for(const source in instructions) {
      if(instructions[source].length === 0) {
        continue
      }

      instructions_html += `
      <p>${source}</p>
      <pre>${instructions[source].join('\n')}</pre>
      `

      instructions_text += `${source}, `
    }

    if(instructions_text === '') {
      return error('There are no accounts to merge')
    }

    // After we do all the checks above, we assume the api call went well
    // and we build the notification message for the user

    const gmail_body = `
    ${old_email}
    %0A%0A
    ${instructions_text.slice(0, -2)}
    `
    const asana_task_gmail_url = get_gmail_url(
      `${process.env.ASANA_TECH_EMAIL},${process.env.ASANA_TASK_ASSIGNEE}`,
      `Member%20merge ${new_email} `,
      gmail_body
    )

    const action_button = `
    <div class="d-flex" id="assign_task_button">
      <a href="${asana_task_gmail_url}" target="_blank" type="button" class="btn btn-secondary ms-auto">Assign to Tech Team</a>
    </div>
    `

    success(
      `
      <p><strong>Merge Instructions</strong></p>
      ${instructions_html}
    `,
      null,
      action_button
    )
  } catch (e) {
    console.error(e)

    error(GENERIC_ERROR_MESSAGE)
  }
}
