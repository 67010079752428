import {clear_notifications} from './notifications'
import spinner from './spinner'

const api_endpoint = process.env.API_ENDPOINT

const request = async (method, path, data = '') => {
  clear_notifications()
  spinner.display()

  const body = data === '' ? null : JSON.stringify(data)

  try {
    const response = await fetch(`${api_endpoint}${path}`, {
      method, body, mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${sessionStorage.access_token}`,
        'Content-Type': 'application/json'
      }
    })
    const {status, statusText} = response

    if(response.status !== 200) {
      throw new Error(`${status} ${statusText}`, {cause: response})
    }

    const json = response.json()
    spinner.hide()

    return json
  }
  catch (error) {
    spinner.hide()

    throw error
  }
}

export default {
  post: (path, data) => request('POST', path, data),
  'delete': path => request('DELETE', path),
  get: path => request('GET', path)
}
