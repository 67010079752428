const spinner = document.getElementById('spinner')

export default {
  display() {
    spinner.classList.add('d-flex')
    spinner.classList.remove('d-none')
  },

  hide() {
    spinner.classList.remove('d-flex')
    spinner.classList.add('d-none')
  }
}
