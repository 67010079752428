import api from '../api'
import {error} from '../notifications'

const GENERIC_ERROR_MESSAGE = 'We had an error in our system. Please try again.'

export async function get_latest_lost_donors(event) {
  event.preventDefault()

  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  if(month < 10){
    month = `0${month}`
  }

  try {
    const id = await api.get(`/donors/lost/monthly/latest?month=${month}&year=${year}`)
    window.open(`https://identity.actionstation.org.nz/lists/${id}`)
  } catch(e) {
    if(e.cause.status === 404){
      error(`Can't find the list for ${month}/${year}. Please contact Tech team`)
    } else {
      error(GENERIC_ERROR_MESSAGE)
    }
  }
}
