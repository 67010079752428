import {authenticate, logout} from './authentication'
import {member_delete, member_merge} from './modules/members.js'
import {run_odr} from './modules/run_odr'
import {get_latest_regular_donors} from './modules/get_latest_regular_donors'
import {get_latest_lost_donors} from './modules/get_latest_lost_donors'
import {hr_offboard} from './modules/hr'
import {error} from './notifications'

function ready(fn) {
  if(document.readyState != 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

// when the document is ready, do this
ready(async () => {
  try {
    await authenticate()

    if(!sessionStorage.user || !sessionStorage.access_token) {
      return
    }

    const logout_button = document.getElementById('logout')
    logout_button.addEventListener('click', logout)

    const logged_in = document.getElementById('logged_in')
    logged_in.classList.remove('d-none')

    const member_delete_form = document.getElementById('member_delete_form')
    member_delete_form.addEventListener('submit', member_delete)

    const member_merge_form = document.getElementById('member_merge_form')
    member_merge_form.addEventListener('submit', member_merge)

    const run_odr_form = document.getElementById('run_odr_form')
    run_odr_form.addEventListener('submit', run_odr)

    const get_latest_regular_donors_form = document.getElementById('get_latest_regular_donors_form')
    get_latest_regular_donors_form.addEventListener('submit', get_latest_regular_donors)

    const get_latest_lost_donors_form = document.getElementById('get_latest_lost_donors_form')
    get_latest_lost_donors_form.addEventListener('submit', get_latest_lost_donors)

    const hr_offboard_form = document.getElementById('hr_offboard_form')
    hr_offboard_form.addEventListener('submit', hr_offboard)

    // activate the first tab
    document.querySelector('#v-pills-members-tab').classList.add('active')
    document.querySelector('#v-pills-members').classList.add('active')
  } catch (e) {
    // @TODO when showing the error, provide a link to refresh the app
    return error(e.message)
  }
})
