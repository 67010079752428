import api from '../api'
import {success, error} from '../notifications'
import {get_gmail_url} from '../utils'

const GENERIC_ERROR_MESSAGE = 'We had an error in our system. Please try again.'

export async function hr_offboard(event) {
  event.preventDefault()

  const staff_email = document.getElementById('offboarding_staff_email').value
  const authorisation_key = document.getElementById(
    'offboarding_auth_key'
  ).value

  const data = {staff_email, authorisation_key}
  try {
    const response = await api.post('/offboarding', data)
    let offboarded_html = ''
    let manual_offboarding_html = ''
    let offboard_instructions_text = ''
    console.log(response)
    for(const source in response) {
      if(!response[source]) {
        manual_offboarding_html += `<p>${source}</p>`
        offboard_instructions_text += `${source}, `
      } else {
        offboarded_html += `<p>${source}</p>`
      }
    }
    const gmail_body = `
      ${staff_email}
      %0A%0A
      ${offboard_instructions_text.slice(0, -2)}
    `
    const asana_task_gmail_url = get_gmail_url(
      `${process.env.ASANA_TECH_EMAIL},${process.env.ASANA_TASK_ASSIGNEE}`,
      `Manually%20offboard ${staff_email} `,
      gmail_body
    )

    const action_button = `
      <div class="d-flex" id="assign_task_button">
        <a href="${asana_task_gmail_url}" target="_blank" type="button" class="btn btn-secondary ms-auto">Assign to Tech Team</a>
      </div>
    `
    success(
      `
       <p><strong>Offboarded Correctly from:</strong></p>
        ${offboarded_html}
        <p><strong>Needs Manual Offboarding from:</strong></p>
        ${manual_offboarding_html}
      `,
      null,
      action_button
    )
  } catch (e) {
    if(e.cause) {
      const error_object = await e.cause.json()
      return error(error_object.message)
    }

    error(GENERIC_ERROR_MESSAGE)
  }
}
