// @TODO use uuid to generate the notifications ids
// it will be better and there will be less change of id repetition

const notifications = document.getElementById('notifications')

const hide_after_delay = (id, hide_delay) =>
  setTimeout(() => {
    const alert_node = document.querySelector(`#${id}`)
    const alert = bootstrap.Alert.getOrCreateInstance(alert_node)
    alert.close()
  }, hide_delay)

const add_notification = (id, type, message, action_button = '') =>
  notifications.innerHTML += `
  <div id="${id}" class="alert alert-${type} alert-dismissible fade show mt-2" role="alert">
    <span>${message}</span>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    ${action_button}
  </div>
`

export const success = (message, hide_delay, action_button = '') => {
  const id = `notification_success_${Date.now()}`

  add_notification(id, 'success', message, action_button)

  if(Number.isFinite(hide_delay)) {
    hide_after_delay(id, hide_delay)
  }
}

export const error = (message, hide_delay, action_button = '') => {
  const id = `notification_error_${Date.now()}`
  add_notification(id, 'danger', message, action_button)

  if(Number.isFinite(hide_delay)) {
    hide_after_delay(id, hide_delay)
  }
}

export const clear_notifications = () => notifications.innerHTML = ''
